import React from 'react';
import { Helmet } from "react-helmet"

import * as s from './notre-entreprise.module.scss';
import wordpressLogo from './wordpress-logo.png';
import peopleImg from './people.jpg';
import qualityWebsiteImg from './quality-website.jpg';

import Nav from '../components/Nav';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import { Link } from 'gatsby';
import crownFavicon from '../images/crown.png';
import heroImageCut from '../images/hero-websites-design-cut.png'
import Head from '../components/Head';

export default function HomePage() {
    return (
        <div>
            <Head>
                <title>Créations et design de site webs - Websites & Design</title>
            </Head>
            <Nav />
            <Hero
                smallTitle
                color="#b8e5a9"
                rightSide={
                    <div id="quickLinks">
                        <h6>Sur cette page</h6>
                        <a href={`#${s.content}`}>Présentation</a>
                        <a href={`#${s.projectTimelineTitle}`}>Étapes de fabrication</a>
                    </div>
                }
            >
                <h1>Pourquoi nous ?<br/>Notre entreprise</h1>
            </Hero>

            <div id={s.content} className="container">

                <div className={`row ${s.fold} ${s.columnReverseMobile}`}>
                    <div className="col-lg-8">
                        <h3>Nous vous installons et paramétrons WordPress - <a href="https://wordpress.org/" target="_blank">un logiciel gratuit utilisé 1 grande partie du web</a></h3>

                        <p>
                            Tout le monde peut créer un site avec WordPress. Mais cela leur prendrait plus d'1 mois d'apprentissage. Il y a beaucoup de plugins et de thèmes à trier et à essayer, à sélectionner pour votre usage spécifique. L'interface de WordPress est simple.
                            <br/>
                            <br/>Nous avons déjà fait ce travail pour vous et nous avons sélectionné les meilleurs plugins et thèmes. Nous avons de l'expérience dans la création de sites WordPress.
                            Ces plugins, gratuits pour certains et payants pour d'autres, extendront votre site pour ajouter du design, des statistiques de visite, des réservations en ligne, une galerie photo, les paiements en ligne...
                            <br/><br/>Avec WordPress, le site vous appartiendra.
                            Vous aurez la possibilité de changer le design des pages, de changer le texte, de changer les couleurs (certaines modifications sont plus complexes).
                            Vous pourrez rajouter des articles, des pages, changer les menus, ... de façon illimitée.
                            <br /><br/>
                        </p>
                    </div>

                    <div className="col-lg-3 offset-lg-1     d-flex justify-content-center align-items-center ">
                        <img style={{ maxWidth: 250 }} src={wordpressLogo} alt="WordPress logo" className={s.foldImage} />
                    </div>
                </div>



                <div className={`row ${s.fold}`}>
                    <div className="col-lg-4     d-flex justify-content-center align-items-center ">
                        <img src={peopleImg} alt="Best team" className={s.foldImage} />
                    </div>
        
                    <div className="col-lg-7 offset-lg-1">
                        <h3>Notre équipe</h3>

                        <p>
                            Notre équipe développe aujourd'hui des sites webs avec WordPress et possède des compétences variées.
                            Notre force vient de notre flexibilité.
                            <br/>
                            <br/>Nous avons l'expertise technique de développement informatique avec 1 collaborateur ayant travaillé avec des grandes entreprises du web comme DailyMotion, Spendesk, Shotgun.
                            Nous avons réalisé plusieurs sites webs WordPress pour un lodge de tourisme, une professeur de yoga, un établissement éducatif.
                            Nous avons développé l'aspect commercial de certains sites webs, à travers le SEO (référencement de votre site sur les sites de recherche), l'analyse de votre activité, la rédaction de textes, le choix d'images et vidéos, des fonctionnalités pour contacter ou recontacter de nouveaux clients.
                            1 collaborateur pourra photographier vos produits, vous, vos lieux d'activité.
                        </p>
                    </div>
                </div>


                <div className={`row ${s.fold} ${s.columnReverseMobile}`}>
                    <div className="col-lg-7">
                        <h3>Un site fiable, beau, customisable</h3>

                        <p>
                            Nous vous créerons un site de qualité, beau, et complet.
                            <br/><br/>Selon votre situation, nous vous proposons plusieurs designs et plusieurs plugins pour correspondre à vos souhaits et vos besoins.
                            Nous retouchons le design de votre site et nous paramétrons les fonctionnalités pour votre activité.
                            Nous installons votre site web sur un hébergeur internet réputé.
                            Nous créeons des sites simples et stables, qui fonctionneront toujours, pour votre présence en ligne.
                            <br/><br/>Ce que vous cherchez n'est pas affiché sur notre site ? Contactez-nous, il est fort probable que nous ayions une solution à vous proposer.
                        </p>
                    </div>

                    <div className="col-lg-4 offset-lg-1     d-flex justify-content-center align-items-center ">
                        <img src={qualityWebsiteImg} alt="Quality website" className={s.foldImage} />
                    </div>
                </div>

                <h2 id={s.projectTimelineTitle}>Comment nous créeons votre site web, votre boutique en ligne</h2>

                <div id={s.projectTimeline}>
                    <div className={s.step}>
                        <div className={s.stepLine}>
                            <div className={s.stepBubble}>
                                1.
                            </div>
                        </div>

                        <div className={s.stepContent}>
                            <h3>Nous discutons de votre site web</h3>

                            <p>
                                Nous échangeons avec vous par email ou par téléphone.
                                <br/>Nous écoutons vos demandes et répondons à vos interrogations.
                                <br/>Nous vous présentons un choix entre quelques thèmes, qui constituera la base graphique de votre site
                                <br/>Nous vous envoyons un devis final!
                                <br/>Nous récoltons les contenus que vous voudrez afficher sur votre site web.
                                <br/>Nous créeons les pages de votre site web - éventuellement, nous rédigeons vos textes, sélectionnons des photos pour vous ou créons un logo (payant)
                                <br/>Nous vous demandons des informations techniques et spécifiques à votre business.
                            </p>
                        </div>
                    </div>

                    <div className={s.step}>
                        <div className={s.stepLine}>
                            <div className={s.stepBubble}>
                                2.
                            </div>
                        </div>

                        <div className={s.stepContent}>
                            <h3>Nous réalisons votre site web, votre boutique en ligne</h3>

                            <p>
                                Nous réalisons votre site, en quelques jours jusqu'à 1 semaine pour un site simple.
                                <br/>Nous vous contactons durant cette période pour vous demander des renseignements supplémentaires.
                                <br/>Lors de projets web plus complexes, ceux-ci prendrons plusieurs semaines de travail. <strong>Vous testerez votre site web en construction en temps réel via une adresse internet privée</strong>
                            </p>
                        </div>
                    </div>

                    <div className={s.step}>
                        <div className={s.stepLine}>
                            <div className={s.stepBubble}>
                                3.
                            </div>
                        </div>

                        <div className={s.stepContent}>
                            <h3>Nous livrons votre site web et procédons à un cycle de retouches</h3>

                            <p>
                                Nous vous livrons votre site sur votre adresse web !
                                <br/>Après que vous l'ayiez visité, nous serons disponibles pour procéder à quelques retouches :
                                changement de taille ou de position d'un bloc, rajout d'un texte ou d'une image, modification des paramètres de fonctionnement, couleurs ...
                            </p>
                        </div>
                    </div>

                    {/*<div className={s.step}>
                        <div className={s.stepLine}>
                            <div className={s.stepBubble}>
                                4.
                            </div>
                        </div>

                        <div className={s.stepContent}>
                            <h3>Accompagnement technique annuel (optionel)</h3>

                            <p>
                                Votre site web est livré avec une assistance incluse en cas de bug ou de dysfonctionnement.
                                <br/>
                                <br/>Si vous souhaitez que nous vous accompagnions annuellement pour : rajouter des textes ou des images, rajouter des blocs, vous conseiller sur les offres digitales, ...
                                Nous vous proposons un accompagnement technique sur 6 mois au prix de 100 euros.
                            </p>
                        </div>
                    </div>*/}
                </div>
            </div>

            <Footer />
        </div>
    )
}