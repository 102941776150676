// extracted by mini-css-extract-plugin
export var content = "notre-entreprise-module--content--2JhIT";
export var columnReverseMobile = "notre-entreprise-module--columnReverseMobile--3px_j";
export var fold = "notre-entreprise-module--fold--3jp9X";
export var foldImage = "notre-entreprise-module--foldImage--R7wPR";
export var projectTimelineTitle = "notre-entreprise-module--projectTimelineTitle--3JQdc";
export var projectTimeline = "notre-entreprise-module--projectTimeline--2tnol";
export var step = "notre-entreprise-module--step--b5rkK";
export var stepLine = "notre-entreprise-module--stepLine--2aVhk";
export var stepBubble = "notre-entreprise-module--stepBubble--cSdcf";
export var stepContent = "notre-entreprise-module--stepContent--1XY-6";